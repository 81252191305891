<template>
  <div class="relative w-32 h-32 mb-6">
    <span
      class="block absolute top-1/2 left-1/2 -mt-16 -ml-16 w-32 h-32 rounded-full border-transparent border-2 text-red-300"
      style="border-top-color: currentcolor; animation: spin 2s linear infinite"
    />
    <span
      class="block absolute top-1/2 left-1/2 -mt-14 -ml-14 w-28 h-28 rounded-full border-transparent border-2 text-red-500"
      style="border-top-color: currentcolor; animation: spin 1.5s linear infinite"
    />
    <span
      class="block absolute top-1/2 left-1/2 -mt-12 -ml-12 w-24 h-24 rounded-full border-transparent border-2 text-red-700"
      style="border-top-color: currentcolor; animation: spin 1.25s linear infinite"
    />
  </div>
  <p class="text-center text-xl">{{ message }}</p>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    message: [String, Object]
  }
};
</script>

<style></style>
