<template>
  <label class="block cursor-pointer" :for="id">{{ label }}</label>
  <span v-if="errorMessage" class="block text-brand mb-1">{{
    errorMessage
  }}</span>
  <input
    class="block border-2 w-full mb-4 px-2 rounded-md"
    :class="errorMessage ? 'border-brand' : ''"
    v-bind="$attrs"
    :type="type"
    :name="name"
    :id="id"
    :value="modelValue"
    @input="handleInput"
  />
</template>

<script>
import { computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CInput',

  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  setup(props, { emit }) {
    const id = uuidv4();

    const errorMessage = computed(() =>
      props.error?.field === props.name && !props.modelValue
        ? props.error?.message
        : null
    );

    const handleInput = e => {
      emit('update:modelValue', e.target.value);
    };

    return {
      errorMessage,
      handleInput,
      id
    };
  }
};
</script>
