<template>
  <label class="block" :for="id">{{ label }}</label>
  <div
    @dragenter.prevent.stop="setHighlight(true)"
    @dragleave.prevent.stop="setHighlight(false)"
    class="relative flex flex-wrap gap-8 w-full p-4 mb-4  rounded-md transition-colors duration-300"
    :class="[
      highlight ? 'bg-gray-100' : 'bg-gray-200',
      noFilesLoaded ? 'h-60' : ''
    ]"
  >
    <p
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"
      v-if="noFilesLoaded"
    >
      Drag your screenshots here or<br />click to browse your files
    </p>

    <input
      class="opacity-0 absolute w-full h-full cursor-pointer"
      type="file"
      accept="image/*"
      :name="name"
      :id="id"
      multiple
      @change="handleFiles"
    />

    <div
      :key="index"
      v-for="(file, index) in files"
      class="group relative w-36 z-10"
    >
      <span
        class="absolute top-1 right-1 w-5 p-0.5 text-gray-700 bg-white bg-opacity-75 rounded-md cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        @click="removeFile(file)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      </span>
      <img v-if="file.url" :src="file.url" class="w-full rounded-md" />
      <p class="text-center text-sm text-gray-800">{{ file.name }}</p>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { computed, ref } from 'vue';

export default {
  name: 'FileUpload',

  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      requird: true
    },
    modelValue: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const id = uuidv4();

    const highlight = ref(false);

    const files = computed(() => props.modelValue);
    const noFilesLoaded = computed(
      () => Object.keys(props.modelValue).length === 0
    );

    const handleFiles = async e => {
      const length = e.target.files.length;
      const files = {};

      for (let i = 0; i < length; i++) {
        const file = e.target.files[i];
        const item = {};

        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
          continue;
        }

        const imageUrl = await readImage(file);
        item.url = imageUrl;
        const data = await readBinary(file);
        item.data = data;
        item.name = file.name;

        files[item.name] = item;
      }

      emit('update:modelValue', { ...props.modelValue, ...files });
    };

    const readImage = async file => {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();

          reader.onload = () => resolve(reader.result);

          reader.onerror = error => reject(error);

          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    };

    const readBinary = async file => {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();

          reader.onload = () => resolve(reader.result);

          reader.onerror = error => reject(error);

          reader.readAsArrayBuffer(file);
        } catch (error) {
          reject(error);
        }
      });
    };

    const removeFile = file => {
      delete files.value[file.name];
    };

    const setHighlight = value => {
      highlight.value = value;
    };

    return {
      id,
      highlight,
      setHighlight,
      handleFiles,
      noFilesLoaded,
      removeFile,
      files
    };
  }
};
</script>

<style scoped>
.ratio:before {
  content: '';
  padding-bottom: 141%;
  width: 100%;
}
</style>
