<template>
  <div
    class="flex max-w-screen-xl sm:h-20 mt-10 px-10 xl:px-0 mx-auto flex-col sm:flex-row items-center sm:justify-between"
  >
    <img
      class="h-auto w-60 sm:h-full sm:w-auto"
      src="./assets/images/logo.png"
      alt="Bridge logo"
    />
    <img
      class="h-20 sm:h-full w-auto"
      src="./assets/images/logo-client.svg"
      alt="Client logo"
    />
  </div>
  <div class="max-w-screen-xl mt-8 px-10 xl:px-0 mx-auto text-lg text-gray-700">
    <h1 class="text-4xl mb-6">Supporting you</h1>
    <p class="max-w-prose">
      Bridge support services are here to ensure that when technical challenges
      present themselves expert help is at hand. We operate accross business
      hours, 9 to 5:30, Mon-Fir, UK time. As part of the current agreement we
      currently support the following:
    </p>
    <ul class="pl-2 my-5 list-disc list-inside">
      <li class="max-w-prose">
        Showpad - TCO Calculator - Pruchasing Manager
      </li>
      <li class="max-w-prose">Showpad - TCO Calculator - ScanStock</li>
    </ul>
    <p class="max-w-prose">
      If you have a techincal issue or would like to suggest a modification or
      enhancement please complete the form below and we'll be in touch.
    </p>
    <transition name="fade" mode="out-in">
      <div v-if="sendTicket" class="mt-10">
        <h1 class="text-center text-4xl">
          Thank you for your request. We'll get back to you ASAP.
        </h1>
      </div>
      <CForm
        v-else
        class="my-4"
        @submitData="handleSubmit"
        :loading="loading"
        :error="validationError"
      />
    </transition>
  </div>
  <transition name="fade">
    <div
      v-if="loading || errorMessage"
      class="fixed w-full h-full top-0 left-0 bg-white bg-opacity-90"
    >
      <div
        class="flex flex-col items-center justify-center w-11/12 max-w-7xl bg-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-xl shadow-lg"
        :class="loading ? 'h-96 p-10' : 'p-4'"
      >
        <Loader v-if="!errorMessage" :message="loadingMessage" />
        <div class="w-full mt-8" v-else>
          <p class="text-center text-xl">
            Wooh, something unexpected happened!<br />Please try again in a few
            minutes
          </p>
          <button
            class="block ml-auto mt-4 px-4 py-1 bg-brand hover:bg-white text-white hover:text-brand border border-white hover:border-brand rounded-md"
            @click="errorMessage = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import axios from 'axios';

import CForm from '@/components/Form';
import Loader from '@/components/Loader.vue';

export default {
  name: 'App',
  components: {
    CForm,
    Loader
  },

  setup() {
    let fileApi;
    const getApiConfig = () => axios.get(process.env.VUE_APP_API_URL);

    const sendTicket = ref(false);
    const loading = ref(false);
    const loadingMessage = ref(null);
    const currentFile = ref(null);
    const validationError = ref(null);
    const errorMessage = ref(false);

    const uploadProgress = e => {
      const percentage = Math.round((e.loaded * 100) / e.total);
      loadingMessage.value = `Uploading ${currentFile.value.name}: ${percentage}%`;
    };

    const handleSubmit = async data => {
      try {
        loading.value = true;
        let token;
        const { form, files } = data;
        const authHeader = `Basic ${btoa(`${form.email}:`)}`;
        fileApi.defaults.headers.Authorization = authHeader;

        currentFile.value = files[0];
        if (currentFile.value !== undefined) {
          const { data: uploadRes } = await fileApi.post('/', files[0].data, {
            params: { filename: files[0].name },
            onUploadProgress: uploadProgress
          });

          token = uploadRes?.upload?.token ?? null;

          for (let i = 1; i < files.length; i++) {
            const file = files[i];
            currentFile.value = file;

            await fileApi.post('/', file.data, {
              params: {
                filename: file.name,
                token
              },
              onUploadProgress: uploadProgress
            });
          }
        }

        loadingMessage.value = 'Creating your suppport ticket';
        await axios.post(process.env.VUE_APP_API_URL, {
          ...form,
          ...(token && {
            uploads: [token]
          })
        });
        sendTicket.value = true;
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            validationError.value = error.response.data.message;
            return;
          }
          errorMessage.value = true;
        }
        console.error(error);
      } finally {
        loading.value = false;
        loadingMessage.value = null;
      }
    };

    onBeforeMount(async () => {
      const {
        data: { message: url }
      } = await getApiConfig();
      fileApi = axios.create({
        baseURL: url,
        headers: {
          'Content-Type': 'application/binary'
        }
      });
    });

    return {
      validationError,
      errorMessage,
      handleSubmit,
      loading,
      loadingMessage,
      sendTicket
    };
  }
};
</script>

<style>
@keyframes spin {
  from {
    transform: rotate(0deg) translateZ(0);
  }
  to {
    transform: rotate(360deg) translateZ(0);
  }
}
</style>
