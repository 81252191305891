<template>
  <label class="block" :for="id">{{ label }}</label>
  <span v-if="errorMessage" class="block text-brand mb-1">{{
    errorMessage
  }}</span>
  <textarea
    class="w-full border-2 mb-4 px-2 rounded-md"
    :class="errorMessage ? 'border-brand' : ''"
    :name="name"
    :id="id"
    v-bind="$attrs"
    :value="modelValue"
    @input="handleInput"
  ></textarea>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { computed } from 'vue';

export default {
  name: 'CTextArea',

  props: {
    modelValue: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  setup(props, { emit }) {
    const id = uuidv4();

    const errorMessage = computed(() =>
      props.error?.field === props.name && !props.modelValue
        ? props.error?.message
        : null
    );

    const handleInput = e => {
      emit('update:modelValue', e.target.value);
    };

    return {
      id,
      handleInput,
      errorMessage
    };
  }
};
</script>

<style></style>
