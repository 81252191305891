<template>
  <form @submit.prevent="handleSubmit" autocomplete="on">
    <fieldset class="border-2 p-4 rounded-md">
      <legend class="px-2 leading-none">Raise a support ticket</legend>
      <CInput
        name="name"
        label="Name"
        type="text"
        autocomplete="name"
        v-model="form.name"
        :error="error"
        required
      />
      <CInput
        name="email"
        label="Email address"
        type="email"
        autocomplete="email"
        v-model="form.email"
        :error="error"
        required
      />
      <CSelect
        label="Which tool does this relate to?"
        name="tool"
        v-model="form.tool"
        :options="toolOptions"
        :error="error"
        required
      />
      <CSelect
        label="What is your query about?"
        name="query"
        v-model="form.query"
        :options="queryOptions"
        :error="error"
        required
      />
      <template v-if="form.query">
        <CInput
          name="subject"
          :label="subjectTitle"
          type="text"
          v-model="form.subject"
          :error="error"
          required
        />
        <CTextArea
          name="description"
          :label="descriptionTitle"
          v-model="form.description"
          rows="5"
          :error="error"
          required
        />
      </template>
      <template v-if="extraOptions">
        <div class="md:flex">
          <div class="flex-1 md:mr-8">
            <CSelect
              label="What device are you using?"
              name="device"
              v-model="form.device"
              :options="deviceOptions"
              :error="error"
              required
            />
          </div>
          <div class="flex-1 md:ml-8">
            <CSelect
              label="What browser are you using?"
              name="browser"
              v-model="form.browser"
              :options="browserOptions"
              :error="error"
              required
            />
          </div>
        </div>
        <FileUpload
          name="files"
          label="Please add supporting files"
          v-model="files"
        />
      </template>
      <button
        type="submit"
        class="px-4 py-1"
        :class="
          loading
            ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
            : 'bg-brand text-white'
        "
        :disabled="loading"
      >
        Submit
      </button>
    </fieldset>
  </form>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';

import CInput from '@/components/CInput';
import CSelect from '@/components/CSelect';
import CTextArea from '@/components/CTextArea';
import FileUpload from '@/components/FileUpload';

export default {
  name: 'CForm',
  components: {
    CInput,
    CSelect,
    CTextArea,
    FileUpload
  },

  props: {
    loading: Boolean,
    error: Object
  },

  setup(props, { emit }) {
    const form = reactive({
      name: '',
      email: '',
      subject: '',
      description: '',
      tool: '',
      query: '',
      browser: '',
      device: '',
      checkExtraFields: false,
      extraFields: ['browser', 'device']
    });

    if (process.env.NODE_ENV === 'development') {
      form.name = 'Niek van Sleeuwen';
      form.email = 'niekvansleeuwen@hotmail.com';
      form.subject = 'This is generated from the test environment';
      form.tool = 'TCO Calculator - Purchasing Manager';
      // form.query = 'I have a technical issue';
      // form.description =
      //   'I have such an insanely difficult problem. Bridge please help me!';
    }

    const toolOptions = [
      'TCO Calculator - Purchasing Manager',
      'TCO Calculator - ScanStock'
    ];
    const queryOptions = [
      "I don't know how to do something",
      'I have a technical issue',
      'I want to suggest an improvement'
    ];
    const deviceOptions = [
      'Apple iPad',
      'Laptop PC',
      'Desktop PC',
      'Alternative Tablet'
    ];
    const browserOptions = [
      'Google Chrome',
      'Microsoft Edge',
      'Safari',
      'Firefox'
    ];

    const files = ref({});
    const extraOptions = computed(() => form.query === queryOptions[1]);
    const subjectTitle = computed(() => {
      let title;
      switch (form.query) {
        case queryOptions[0]:
          title = 'Please describe your goal in one sentence';
          break;
        case queryOptions[1]:
          title = 'Please describe your issue in one sentence';
          break;
        case queryOptions[2]:
          title = 'Please describe your improvement in one sentence';
          break;
      }
      return title;
    });
    const descriptionTitle = computed(() => {
      let title;
      switch (form.query) {
        case queryOptions[0]:
          title = 'Please describe what you are trying to do';
          break;
        case queryOptions[1]:
          title = 'Please describe your issue';
          break;
        case queryOptions[2]:
          title = 'Please describe your improvement';
          break;
      }
      return title;
    });

    const handleSubmit = () => {
      emit('submitData', { form, files: Object.values(files.value) });
    };

    watch(extraOptions, extraOptions => {
      if (extraOptions === true) {
        form.extraFields = extraOptions;
      } else {
        form.extraFields = extraOptions;
      }
    });

    return {
      extraOptions,
      form,
      files,
      toolOptions,
      queryOptions,
      deviceOptions,
      browserOptions,
      handleSubmit,
      subjectTitle,
      descriptionTitle
    };
  }
};
</script>
